import { Link, navigate, useStaticQuery, graphql } from "gatsby"
import * as R from "ramda"
import React, { useContext, Fragment, useState, useEffect, useRef } from "react"
import { useLocation } from "@reach/router"

import { FirebaseContext } from "../firebase"
import { useMicroCopySv } from "../hooks/microCopy/sv"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import Button from "@material-ui/core/Button"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import Logo from "../images/logo_navi_pysty 1.svg"
import LogoSv from "../images/logo_navi_sv.svg"
import User from "../images/user.svg"
import Signout from "../images/exit.svg"

import LogoStripped from "../images/logostripped.svg"

import * as styles from "./header.module.scss"

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const Header = ({language}) => {
  const mobileSizeDetected = useMediaQuery("(max-width:1023px)")
  // UI texts
  const microCopyTexts = language === 'sv' ? useMicroCopySv : useMicroCopyFi

  const labelLoginVirtual = GetMicroCopy(
    microCopyTexts,
    "yleinenKirjauduVirtuaaliin"
  )
  const labelLogout = GetMicroCopy(microCopyTexts, "yleinenKirjauduUlos")
  const labelBuyTickets = GetMicroCopy(microCopyTexts, "yleinenOstaLiput")
  const labelBuyTicketsUrl = GetMicroCopy(microCopyTexts, "yleinenOstaLiputUrl")
  // end UI texts
  // nav items

  const {
    contentfulNavigaatio,
    contentfulNavigaatioSv,
  }  = useStaticQuery(graphql`
    query NavQuery {
      contentfulNavigaatio: contentfulNavigaatio(name: { eq: "Päänavigaatio" }, node_locale: { eq: "fi" }) {
        name
        links {
          id
          key
          value
          subitems {
            key
            value
            id
          }
        }
      }
      contentfulNavigaatioSv: contentfulNavigaatio (name: { eq: "Päänavigaatio" }, node_locale: { eq: "sv" }) {
        name
        links {
          id
          key
          value
          subitems {
            key
            value
            id
          }
        }
      }
    }
  `)

  const navItems = language === 'sv' ? contentfulNavigaatioSv.links : contentfulNavigaatio.links
  const { firebase, user } = useContext(FirebaseContext)
  const [showMenu, setShowMenu] = useState(null)
  const [showSubMenu, setShowSubMenu] = useState([])
  const location = useLocation()
  const prevLocation = usePrevious(location)

  // menu toggling
  function toggleMenu() {
    showMenu ? setShowMenu(false) : setShowMenu(true)
    document.body.classList.toggle("menu-open", !showMenu)
  }

  // Submenu toggling
  function toggleSubMenu(id) {
    const secondLevelShownState = showSubMenu.slice()
    const index = secondLevelShownState.indexOf(id)
    secondLevelShownState.splice(index, 1)
    if (index < 0) {
      secondLevelShownState.push(id)
    }
    setShowSubMenu(secondLevelShownState)
  }

  function saveUserLogoutAction() {
    firebase
      .addUserAction({ email: user.email, action: "logout" })
      .then(ref => {
        firebase
          .logout()
          .then(ref => {
            navigate(`/login`, { replace: false })
          })
          .catch(err => {
            navigate("/login")
            console.error("error logout: ", err)
          })
      })
      .catch(err => {
        console.error("error adding user action: ", err)
      })
  }

  function handleLogoutClick() {
    saveUserLogoutAction()
  }

  // close menus when location changes
  useEffect(() => {
    if (location !== prevLocation) {
      setShowSubMenu([])
      setShowMenu(null)
    }
  }, [location, prevLocation, setShowSubMenu, setShowMenu])

  const NavItems = ({ items }) => {
    const navItems = items.map(item => {
      const subitems = R.path(["subitems"], item)
      return (
        <li
          className={`flex flex-wrap items-center relative mb-5 lg:mb-0 lg:mx-2 xl:mx-3.5 lg:first:ml-0 lg:last:mr-0`}
          key={R.path(["key"], item)}
        >
          <Link
            to={`${R.path(["value"], item)}`}
            key={`/${R.path(["id"], item)}`}
            partiallyActive={true}
            className={`font-sans leading-6 text-black w-full lg:w-auto border-solid border-b-2 border-transparent text-lg lg:text-[15px] lg:hover:border-blue pr-8 lg:p-0`}
            activeClassName="lg:border-blue"
          >
            {R.path(["key"], item)}
          </Link>

          {subitems.length > 0 && (
            <Fragment>
              <button
                className={`p-0 absolute lg:relative top-[-4px] lg:top-[-2px] right-0 lg:ml-1 cursor-pointer bg-transparent border-0 flex items-end justify-center text-blue hover:opacity-60`}
                onClick={() => toggleSubMenu(item.id)}
                aria-label={`${
                  showSubMenu.includes(item.id) ? `Sulje menu` : `Avaa menu`
                }`}
                aria-expanded={showSubMenu.includes(item.id) ? "true" : "false"}
                aria-controls={item.id}
              >
                <KeyboardArrowDownIcon
                  className={`!w-8 !h-8 lg:!w-6 lg:!h-6 ${
                    showSubMenu.includes(item.id) ? `rotate-180` : ``
                  }`}
                />
              </button>
              <ul
                id={item.id}
                className={
                  `m-0 p-5 list-none bg-white  lg:absolute lg:top-full lg:translate-y-[-2px] lg:right-0 lg:z-20 w-full lg:w-40 lg:shadow-lg ` +
                  (showSubMenu.includes(item.id) ? `block` : `hidden`)
                }
              >
                {subitems.map(subitem => {
                  return (
                    <li key={`/${R.path(["id"], subitem)}`}>
                      <Link
                        id={`/${R.path(["id"], subitem)}`}
                        to={`${R.path(["value"], subitem)}`}
                        key={`/${R.path(["id"], subitem)}`}
                        partiallyActive={true}
                        className={`font-sans leading-6 text-black border-solid border-b-2 border-transparent text-[15px] hover:border-blue`}
                        activeClassName={styles.active}
                        activeStyle={{ borderBottomColor: "#69d2fa" }}
                      >
                        {R.path(["key"], subitem)}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </Fragment>
          )}
        </li>
      )
    })
    return (
      <ul className="list-none ml-0 mb-0 p-0 mt-20 w-80 max-h-[70vh] lg:w-auto lg:max-h-full lg:mt-0 lg:flex lg:items-end lg:mb-[-2px]">
        {navItems}

        {language === 'sv' ? (
             <li className="lg:flex items-center relative mb-5 lg:mb-0 lg:mx-2 xl:mx-3.5 lg:first:ml-0 lg:last:mr-0">
              <Link
              to={`/`}
              className={`font-sans leading-6 text-black w-full lg:w-auto border-solid border-b-2 border-transparent text-lg lg:text-[15px] lg:hover:border-blue`}
              activeClassName="lg:border-blue"
            >
              <span className="text-[15px] mr-1">FI </span>
            </Link>
             / <span className="font-bold border-b-2 lg:border-blue text-[15px] ml-1"> SV</span>
            </li>
            ) : (
              <li className="lg:flex items-center relative mb-5 lg:mb-0 lg:mx-2 xl:mx-3.5 lg:first:ml-0 lg:last:mr-0">
              <span className="font-bold border-b-2 lg:border-blue text-[15px] mr-1">FI </span> /
              <Link
              to={`/sv`}
              className={`font-sans leading-6 text-black w-full lg:w-auto border-solid border-b-2 border-transparent text-lg lg:text-[15px] lg:hover:border-blue`}
              activeClassName="lg:border-blue"
            >
              <span className="ml-1"> SV</span>
            </Link>
            </li>
          )}

      </ul>
    )
  }

  return (
    <header className="bg-white lg:flex lg:justify-center lg:items-end relative border-b-[1px] border-blue h-12 lg:h-[150px]">
      <nav className="px-5 lg:relative lg:flex lg:items-end lg:pb-5 lg:min-w-800">

      {language === 'sv' ? (
        <Link to={`/sv`}>
          <div className="pr-8">
            {mobileSizeDetected ? (
              <img
                src={LogoStripped}
                className="absolute z-30 top-2 left-5"
                alt="Bostadsmässan"
              />
            ) : (
              <div>
              <img className="mb-0" src={LogoSv} alt="Bostadsmässan" />
              </div>
            )}
          </div>
        </Link>
      ) : (
        <Link to={`/`}>
          <div className="pr-8">
            {mobileSizeDetected ? (
              <img
                src={LogoStripped}
                className="absolute z-30 top-2 left-5"
                alt="Asuntomessut"
              />
            ) : (
              <img className="mb-0" src={Logo} alt="Asuntomessut" />
            )}
          </div>
        </Link>
      )}

        <button
          className="absolute z-30 p-0 bg-transparent border-none text-blue top-2 right-5 lg:hidden"
          onClick={toggleMenu}
          aria-expanded={showMenu ? "true" : "false"}
          aria-controls="menu-container"
          aria-label={`${showMenu ? `Sulje menu` : `Avaa menu`}`}
        >
          {showMenu ? (
            <CloseIcon className="!w-8 !h-8" />
          ) : (
            <MenuIcon className="!w-8 !h-8" />
          )}
        </button>

        <div
          id="menu-container"
          className={` ${mobileSizeDetected ? `hidden` : `block`} ${
            showMenu
              ? `!flex flex-col items-center fixed top-12 left-0 w-screen h-screen bg-white z-20 lg:top-0 lg:relative lg:w-auto lg:h-auto`
              : ``
          }`}
        >
          <NavItems items={navItems} />

          <div className="navButtons mt-6 lg:mt-0 lg:pr-5 lg:absolute lg:top-[-10px] lg:right-0 flex flex-wrap flex-col md:flex-row gap-3 w-80 lg:w-auto text-center lg:text-left">
            {
            /*
            <div>
            <a href={labelBuyTicketsUrl} className="orange">
              <Button variant="contained" color="primary">
                {labelBuyTickets}
              </Button>
            </a>
            </div>
            */
            }


            {(!user || !user.email) && (
              <div>
                {labelLoginVirtual &&
                <Link to="/login" className="orange">
                  <Button variant="contained" color="primary">
                    {labelLoginVirtual}
                  </Button>
                </Link>
                }
              </div>
            )}

            {!!user && !!user.email && (
              <div>
                {labelLogout &&
                <div className="mt-5 ml-2 lg:mt-0">
                  <div
                  className="flex text-[14px] justify-center lg:justify-start items-center -mt-1 mb-2 lg:mb-1">
                    <img
                    src={User}
                    className="h-4 pr-2 m-0"
                    alt=""
                    />
                    <Link to="/profile" className="text-black">
                      Oma profiili
                    </Link>
                  </div>
                  <div className="flex text-[14px] text-black justify-center lg:justify-start items-center">
                    <img
                      src={Signout}
                      className="h-4 pr-2 m-0"
                      alt=""
                    />
                    <a onClick={handleLogoutClick} className="text-black cursor-pointer">
                      {labelLogout}
                    </a>
                  </div>
                </div>
                }
              </div>
            )}
          </div>


        </div>
      </nav>
    </header>
  )
}

export default Header
