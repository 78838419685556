/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useMicroCopySv } from "../hooks/microCopy/sv"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import { ThemeProvider } from "@material-ui/styles"
import AsuntomessutTheme from "./theme"
import Header from "./header"
import Footer from "./footer"
import "./layout.scss"
import "../styles/tailwind.css"
import { FirebaseContext, useAuth } from "../firebase"
import DialogNewsletter from "../components/dialogNewsletter"
import { navigate } from "gatsby"
const Layout = ({ children, language }) => {
  const { user, firebase, loading } = useAuth()

  // UI texts
  const microCopyTexts = language === 'sv' ? useMicroCopySv : useMicroCopyFi
  const labelPopUpTitle = GetMicroCopy(microCopyTexts, "uutiskirjePopUpOtsikko")
  const labelPopUpText = GetMicroCopy(microCopyTexts, "uutiskirjePopUpTeksti")
  const labelPopUpCta = GetMicroCopy(microCopyTexts, "uutiskirjeTilaa")
  const labelPopUpClose = GetMicroCopy(microCopyTexts, "yleinenSulje")
  // end UI texts
  React.useEffect(() => {
    /*
    let timeoutId;
  
    const authorise = async () => {
      if (!firebase) return;
  
      const response = await firebase?.getUser({ email: user?.email });
      if (response && response.data && response.data.contentAccess !== 'Authorised') {
        navigate('/code', { state: { returnUrl: '/loviisa-2023' } });
      }
    };
  
    const pathnameSegments = window.location.pathname.split('/');
    if (pathnameSegments[1] === 'loviisa-2023' && pathnameSegments[2] === 'kohteet' && pathnameSegments[3]) {
      authorise();
    }
  
    // Cleanup function, if the component is unmounted before the timeout
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
    */
  }, [firebase, user]);
  
  
  
  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <ThemeProvider theme={AsuntomessutTheme}>
        <Header language={language}/>
        {/* Push footer to bottom on large screens with minheight and not much content */}
        <div
          style={{
            margin: `0 auto`,
            maxWidth: "100%",
            minHeight: "calc(100vh - 827px)",
          }}
        >
          {!user && language === 'fi' && (
            <DialogNewsletter
              title={labelPopUpTitle}
              text={labelPopUpText}
              cta={labelPopUpCta}
              closeLabel={labelPopUpClose}
              language={language}
            />
          )}
          <main>{children}</main>
        </div>
        <Footer language={language}/>
      </ThemeProvider>
    </FirebaseContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
}

export default Layout
